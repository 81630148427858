import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const AgriparkPage = () => {

  const data = useStaticQuery(graphql`
        query {
            LogoAgripark: file(relativePath: { eq: "logo-agripark-colour.svg" }) {
                publicURL
            },
        }
    `);

  /**
   * Returns an article with Dev's bio
   * @returns {JSX.Element}
   */
  const DevMBio = () => {
    return (
      <article className="flex flex-col max-w-screen-lg mx-auto mt-20 text-gray-700 lg:shadow-2xl md:flex-row">
        <section className="w-full lg:w-[70%]">
          <StaticImage
            placeholder="dominantColor"
            imgClassName="grayscale"
            height={450}
            width={420}
            src="../../images/headshot-devm.jpg"
            alt="Headshot of Devraj Mukherjee" />
        </section>
        <section className="flex flex-col justify-between p-4 lg:p-8">
          <div>
            <h2 className="text-3xl font-bold">Dev Mukherjee</h2>
            <p>Principal Designer and Engineer, Anomaly</p>
            <p>Adjunct professional, Charles Sturt University</p>
          </div>
          <div className="py-6">
            <p className="mb-2">
              Dev started Anomaly, after graduating from University to pursue his love of working with open source technologies.
            </p>
            <p className="mb-2">
              He spends his time building foundational technologies that sets Anomaly apart, and gives our customers the technological edge.
            </p>
            <p className="mb-2">
              Dev's adjunct role at the University let's students benefit from our entire company's experience.
            </p>
          </div>
          <div>
            <a href="https://github.com/devraj">
              <FontAwesomeIcon size="2x" className="mr-4 fill-current" icon={faGithub} />
            </a>
            <a href="https://www.linkedin.com/in/devm/">
              <FontAwesomeIcon size="2x" className="mr-4 fill-current" icon={faLinkedin} />
            </a>
          </div>
        </section>
      </article>
    );
  };

  return (
    <Layout>
      <Seo
        title="AgriPark"
        description="The innovation precinct in the heart of the Riverina dedicated to research, curated collaboration and sustainable production."
      />
      <div className="mx-4 mb-16 lg:max-w-screen-lg lg:mx-auto">
        <h1 className="sr-only">AgriPark</h1>
        <img
          className="mx-auto"
          src={data.LogoAgripark.publicURL}
          alt="Argipark - Agriscience, Research and Business Park"
          height={70}
          width={583} />
      </div>
      <div className="w-full lg:hidden">
        <StaticImage
          className="w-full"
          placeholder="dominantColor"
          src="../../images/hero-agripark-grain-effect-mobile.png"
          objectFit="cover"
          alt="AgriPark Hero Illustration" />
      </div>
      <div className="hidden lg:block">
        <StaticImage
          src="../../images/hero-agripark-grain-effect.png"
          placeholder="dominantColor"
          alt="AgriPark Hero Illustration" />
      </div>
      <div className="mx-4 my-8 text-xl text-gray-700 lg:my-16 lg:w-full lg:mx-auto lg:max-w-screen-md lg:text-2xl">
        <p className="mb-6">
          The innovation precinct in the heart of the Riverina is dedicated to research, curated
          collaboration and sustainable production.
        </p>
        <p className="mb-6">
          Where research, collaboration, innovation and sustainability collide. Where international
          agricultural companies, knowledge-rich agribusinesses, food producers, innovative small-medium
          enterprises and Charles Sturt University work side-by-side to tackle complex global issues.
        </p>
        <p className="mb-6">
          Anomaly has worked with Charles Sturt University in completely reimagining course design,
          our principal is an adjunct professional. Anomaly moves their base to the AgriPark to further
          collaboration as a business and a mentoring organisation to future students who pursue a career in technology.
        </p>
        <p className="mb-6">
          Through this partnership we augment the capabilities of partners that are trying to solve problems in the software sphere.
        </p>
        <p className="mb-6">
          Anomaly is committed to their presence in the region and assists technological endeavours to grow in the Riverina.
        </p>
      </div>
      <DevMBio />
    </Layout>
  );
};
export default AgriparkPage
